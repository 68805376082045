import React, { Component } from "react";
import Slider from "react-slick";
import './component.css'; 
import img1 from '../Assets/College/1.webp'
import img2 from '../Assets/College/2.webp'
import img3 from '../Assets/College/3.webp'
import img4 from '../Assets/College/4.webp'
import img5 from '../Assets/College/5.webp'
import img6 from '../Assets/College/6.webp'
import img7 from '../Assets/College/7.webp'
import img8 from '../Assets/College/8.webp'
import img9 from '../Assets/College/9.webp'
import img10 from '../Assets/College/10.webp'
import img11 from '../Assets/College/11.webp'
import img12 from '../Assets/College/12.webp'
import img13 from '../Assets/College/13.webp'
import img14 from '../Assets/College/14.webp'
import img15 from '../Assets/College/15.webp'
import img16 from '../Assets/College/16.webp'
import img17 from '../Assets/College/17.webp'
import img18 from '../Assets/College/18.webp'
import img19 from '../Assets/College/19.webp'
import img20 from '../Assets/College/20.webp'

import img21 from '../Assets/College/21.png'
import img22 from '../Assets/College/22.png'
import img23 from '../Assets/College/23.png'
import img24 from '../Assets/College/24.png'
import img25 from '../Assets/College/25.png'
import img26 from '../Assets/College/26.png'
import img27 from '../Assets/College/27.png'
import img31 from '../Assets/College/31.jpg'
import img28 from '../Assets/College/28.jpg'
import img29 from '../Assets/College/29.jpg'
import img30 from '../Assets/College/30.jpg'
import img32 from '../Assets/College/32.png'

const Marquee = () => {

  const settings = {
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6,
        },
      },
    ],
  };

  // List of image URLs
  const imageUrls = [
    img1,img2,
    img3,img4,
    img5,img6,
    img7,img8,
    img9,img10,
    img11,img12,
    img13,img14,
    img15,img16,
    img17,img18,
    img19,img20,
    // img21,img22,img23,img24,img25,img26,img27,img28,img29,img30,img31,img32
  ]

  return (
    <div className="marquee-container">
    <Slider {...settings} className='marqueebox' >
      {imageUrls.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Image ${index + 1}`} className='marqueeimg p-2' />
      ))}
    </Slider>
  </div>
  );
};

export default Marquee;
