import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Instaposts} from './Instaposts'
import './component.css'

const Instapostsslider = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Move to the next slide
      sliderRef.current.slickNext();
    }, 3000); // Change the interval time as needed

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 500,
      cssEase: "linear",

      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],

  };

  return (
    <>
    <Slider {...settings} ref={sliderRef}>
       {Instaposts.map(Instaposts => (
          <div className="expcard" key={Instaposts.id}>

            <img src={Instaposts.instapostpic} alt='INSTAGRAM POST' className='instaimg mb-2'/>
            
          </div>
        ))}
    </Slider>

<div className='galleryvideo'>
  <div className='galvideo'>
    <div className='videos'>
    <iframe
        className="shadow-1-strong rounded mb-4 videosgallery"
        src="https://www.youtube.com/embed/EPGflAVhgpg?autoplay=0&showinfo=0&controls=0"
        title="Evaa Immigration"
        frameBorder="0" controls="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen 
      ></iframe>

    </div>
    <div className='videos'>
    <iframe
        className="shadow-1-strong rounded mb-4 videosgallery"
        src="https://www.youtube.com/embed/Z5GDPEGqFos?autoplay=0&showinfo=0&controls=0"
        title="Evaa Immigration"
        frameBorder="0" controls="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen 
      ></iframe>
    </div>

    <div className='videos'>
    <iframe
        className="shadow-1-strong rounded mb-4 videosgallery"
        src="https://www.youtube.com/embed/WwqyX5nSgG8?autoplay=0&showinfo=0&controls=0"
        title="Evaa Immigration"
        frameBorder="0" controls="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen 
      ></iframe>
    </div>
  </div>
</div>

</>
    
  );
};

export default Instapostsslider;
