import React from 'react';
import './component.css'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-4 footer">
      <footer className="text-center text-lg-start text-white">
        <div className="container p-4 pb-0">
          <section>
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h4 className="text-uppercase mb-4 font-weight-bold">
                  EVAA IMMIGRATION
                </h4>
                <p className='footeraddress'>
                Evaa Immigration is a company, came into being with the sole objective of providing career guidance and vocational directions to our clients worldwide. 
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  IMMIGRATION
                </h6>
                <p>
                <Link
                  className="Link "
                  to="/Canada"  style={{color:'white'}}
                >Canada</Link>
                </p>
                <p>
                <Link
                  className="Link "
                  to="/Us"  style={{color:'white'}}
                >US</Link>
                </p>
                <p>
                <Link
                  className="Link "
                  to="/Australia"  style={{color:'white'}}
                >Australia</Link>
                </p>
                
              </div>

              <hr className="w-100 clearfix d-md-none" />
{/* 
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                 COURSES
                </h6>
                <p>
                  <a className="link">Diploma</a>
                </p>
                <p>
                  <a className="link">Graduate</a>
                </p>
                <p>
                  <a className="link">Post Graduate</a>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" /> */}

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                 ABOUT EVAA
                </h6>
                <p>
                <Link
                  className="Link "
                  to="/"  style={{color:'white'}}
                >Home</Link>
                </p>
                <p>
                <Link
                  className="Link "
                  to="/About"  style={{color:'white'}}
                >About Us</Link>
                </p>
                {/* <p>
                  <a className="link">IELTS</a>
                </p> */}
                <p>
                <Link
                  className="Link "
                  to="/Gallery"  style={{color:'white'}}
                >Gallery</Link>
                </p>
                <p>
                <Link
                  className="Link "
                  to="/Contact"  style={{color:'white'}}
                >Contact</Link>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                 QUICK LINKS
                </h6>
                <p>
                <Link
                  className="Link "
                  to="/Contact"  style={{color:'white'}}
                >Enquiry Form</Link>
                </p>
                
                <p><Link
                  className="Link "
                  to="/Privacy Policy"  style={{color:'white'}}
                >Privacy Policy</Link>
                  
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

            </div>
          </section>

          <hr className="my-3" />

          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  © 2023 Copyright
                  
                  Evaa Immigration
                  
                </div>
              </div>

              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                {/* <a
                  className="btn btn-outline-light btn-floating m-1 text-white"
                  role="button"
                >
                  <i class="bi-facebook icon"></i>
                </a> */}

                <a href='https://www.youtube.com/@evaaimmigration8713'
                  className="btn btn-outline-light btn-floating m-1 text-white"
                  role="button"
                >
                  <i className="bi-youtube icon"></i>
                </a>

                {/* <a
                  className="btn btn-outline-light btn-floating m-1 text-white"
                  role="button"
                >
                  <i className="bi-linkedin icon"></i>
                </a> */}

                <a href='https://www.youtube.com/@evaaimmigration8713'
                  className="btn btn-outline-light btn-floating m-1 text-white"
                  role="button"
                >
                  <i class="bi-instagram icon"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
