

export const Contactdata = [
    {
        id:1,
        place:'Ludhiana Office',
        address:'5170, Phase 2, Bypass Road, Opp Bal Bharti School, Urban Estate Dugri,  Ludhiana, Punjab 141013',
        phone:'+91 95332-00003, +91 82830-37373',
        email:'info@evaa.ca',
        map:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54796.52280835292!2d75.836363!3d30.864759000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a83ab579b9101%3A0x108cd1ae3341c01a!2sEvaa%20Immigration%20Services!5e0!3m2!1sen!2sin!4v1702893058655!5m2!1sen!2sin"
    },
   
    {
        id:2,
        place:'Ahmedgarh office',
        address:'Old Grain Market, Near Bahadur Garh Gurdwara, Ahmedgarh.',
        phone:'+91 98083-00009',
        email:'info@evaa.ca',
        map:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d109801.92435543015!2d75.82755600000002!3d30.681549!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39107b40e123043f%3A0x68a8820afdd0429f!2sOld%20Grain%20market!5e0!3m2!1sen!2sin!4v1702893576304!5m2!1sen!2sin"
    },

    {
        id:3,
        place:'Gurdaspur Office',
        address:'Opposite Water Supply Tank, Near Chicago Pizza Gurdaspur Road, Kalanaur',
        phone:'+91 9815695428',
        email:'info@evaa.ca',
        map:"https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1372.8648477079037!2d75.14947878133728!3d32.01485596508253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWater%20Supply%20Tank%2C%20Near%20Chicago%20Pizza%20Gurdaspur%20Road%2C%20Kalanaur!5e0!3m2!1sen!2sin!4v1705133782872!5m2!1sen!2sin"
    },

    // {
    //     id:4,
    //     place:'patiala office',
    //     address:'LeelaBhawan, SCO 5, 1st floor, Opposite Gopal Sweets, Patiala',
    //     phone:'+91 9808100009, 1800 1800 909 (Toll Free)',
    //     email:'patiala@evaa.ca',
    //     map:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8552.777405796654!2d76.38583606018214!3d30.33555971871375!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391028c1a31089c3%3A0xbc656b870524bb4c!2sGopal&#39;s%20Sweets!5e0!3m2!1sen!2sin!4v1702894340409!5m2!1sen!2sin"
    // },
]
