import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ScrollToTop from './components/ScrollToTop'

import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Gallery from './pages/Gallery'
import Canada from './pages/Canada'
import Us from './pages/Us'
import Australia from './pages/Australia'

function App() {
  return (
    <>
    <Router>
    <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/About" element={<About/>} />
          <Route exact path="/Contact" element={<Contact/>} />
          <Route exact path="/Gallery" element={<Gallery/>} />
          <Route exact path="/Canada" element={<Canada/>} />
          <Route exact path="/Us" element={<Us/>} />
          <Route exact path="/Australia" element={<Australia/>} />
        </Routes>
   </Router>
    </>
  );
}

export default App;
