import React,{useState} from "react";
import "./css/Home.css";

import Hero from '../section/Hero.jsx'
import About from '../section/About.jsx'
import Services from "../section/Services.jsx";
import Testimonials from "../section/Testimonials.jsx";
import Contact from "../section/Contact.jsx";
import Contactdetails from '../components/Contactdetailssec.jsx'
import Footer from '../components/Footer.jsx'
import VideoModal from '../components/VideoModal.jsx';

const Home = () => {

  const [showVideoModal, setShowVideoModal] = useState(true);

  // Function to close the video modal
  const closeVideoModal = () => {
    setShowVideoModal(false);
  };

 
  return (
    <>
    <div id="Homepage">
    {showVideoModal && <VideoModal onClose={closeVideoModal} />}
      <Hero/>
      <About/>
      <Services/>
      <Testimonials/>
      <Contact/>
      <Contactdetails/>
      <Footer/>
    </div>
    </>
  );
};

export default Home;
