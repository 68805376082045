import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './css/Country.css'
import { Link } from 'react-router-dom'

import img1 from '../Assets/College/Us/1.png'
import img2 from '../Assets/College/Us/2.png'
import img3 from '../Assets/College/Us/3.png'
import img4 from '../Assets/College/Us/4.png'
import img5 from '../Assets/College/Us/5.png'
import img6 from '../Assets/College/Us/6.png'
import img7 from '../Assets/College/Us/7.png'
import img8 from '../Assets/College/Us/8.png'
import img9 from '../Assets/College/Us/9.png'
import img10 from '../Assets/College/Us/10.png'
import img11 from '../Assets/College/Us/11.png'
import img12 from '../Assets/College/Us/12.png'
import img13 from '../Assets/College/Us/13.png'
import img14 from '../Assets/College/Us/14.png'
import img15 from '../Assets/College/Us/15.png'
import img16 from '../Assets/College/Us/16.png'
import img17 from '../Assets/College/Us/17.png'
import img18 from '../Assets/College/Us/18.png'
import img19 from '../Assets/College/Us/19.png'
import img20 from '../Assets/College/Us/20.png'

import img21 from '../Assets/College/Us/21.png'
import img22 from '../Assets/College/Us/22.png'
import img23 from '../Assets/College/Us/23.png'
import img24 from '../Assets/College/Us/24.png'
import img25 from '../Assets/College/Us/25.png'
import img26 from '../Assets/College/Us/26.png'
import img27 from '../Assets/College/Us/27.png'
import img28 from '../Assets/College/Us/28.png'
import img29 from '../Assets/College/Us/29.png'
import img30 from '../Assets/College/Us/30.png'
import img31 from '../Assets/College/Us/31.png'
import img32 from '../Assets/College/Us/32.png'
import img33 from '../Assets/College/Us/33.png'
import img34 from '../Assets/College/Us/34.png'
import img35 from '../Assets/College/Us/35.png'
import img36 from '../Assets/College/Us/36.png'

const Us = () => {

    // List of image URLs
  const imageUrls = [
    img1,img2,img21,img22,
    img3,img4,img23,img24,
    img5,img6,img25,img26,
    img7,img8,img27,img28,
    img9,img10,img29,img30,
    img11,img12,img31,img32,
    img13,img14,img33,img34,
    img15,img16,img35,img36,
    img17,img18,
    img19,img20,
  ]


  return (
    <>
    <div className='Aboutussection'>
    <Navbar/>

    <nav style={{ '--bs-breadcrumb-divider': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E")' }} aria-label="breadcrumb" className='breadcrumb'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to='/' className='Link'>Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">US</li>
      </ol>
    </nav>
    
    <p className='abouttxt1'>US IMMIGRATION</p>


<div className='galbox'>

{imageUrls.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Image ${index + 1}`} className='Galleryposts cimg' />
      ))}
  
</div>
<hr/>  
    <div className='visadetails'>
        <p className='visadetailstxt1'>STUDY VISA</p>
        <p className='visadetailstxt2'>
Us high academic standards and rigorous quality controls mean that you’ll be earning a high-quality education that will open doors for your future and benefit your career over the long term. A Canadian degree, diploma or certificate is globally recognized as being equivalent to those obtained from the United States or Commonwealth countries. <br/> <br/>

The quality of education and living standards in  are amongst the highest in the world, but the cost of living and tuition fees for international students are generally lower than in other countries such as the United States and the United Kingdom. As such,  is often the preferred choice for students attending college or university. With almost all of the world’s ethnic groups represented in , it’s hard not to find ethnic foods and recreation activities associated with specific cultures. In fact, your international student advisor can help you get in touch with any number of ethnic clubs and associations for you to join while you’re here. <br/> <br/>

While you may have heard of or experienced peoples friendly and open nature, you may not have known that the United Nations consistently ranks  as one of the best places in the world to live. As an international student in , you’ll enjoy all of the same freedoms which protect Canadians – respect for human rights, equality, and a stable and peaceful society. <br/> <br/>

WHY STUDY IN THE UNITED STATES<br/><br/>

<ul>
    <li>High Academic Standards: The United States maintains high academic standards and rigorous quality controls, ensuring that students receive a top-notch education that is recognized worldwide. Degrees earned in the U.S. are valued for their quality and credibility.
</li>

<li>Global Recognition: A degree, diploma, or certificate obtained in the United States is globally recognized, equivalent to those from Commonwealth countries and other reputable educational systems.</li>

<li>Quality of Education and Living Standards: The U.S. boasts one of the highest qualities of education and living standards globally. Despite this, the cost of living and tuition fees for international students are often competitive, making it an appealing choice.</li>

<li>Cultural Diversity: The U.S. is a melting pot of cultures, offering a diverse and inclusive environment. Students can engage with a variety of ethnic foods, recreational activities, and cultural experiences from around the world.</li>

<li>Benefits of a U.S. Study Program:
   - High-quality education with rigorous standards.<br/>
   - An immigration-friendly country.<br/>
   - Opportunities for Permanent Residency (P.R.) under various categories.<br/>
   - Job-focused programs with Co-op (internship) options.<br/>
   - Robust economy with a demand for skilled professionals.<br/>
   - A leading destination for healthcare courses with an excellent healthcare system.<br/>
   - Industry-endorsed programs with paid internships for healthcare professionals and engineers.- Post-graduation opportunities to explore job prospects and Permanent Residency.<br/>
   - Options to study in various states with potential pathways to Permanent Residency.
</li>
    
</ul><br/><br/>

<b>TYPES OF TAUGHT DEGREES</b>

<ul>
    <li>Certificates and Diplomas</li>
    <li>Bachelor Degrees</li>
    <li>Masters Degrees</li>
    <li>Post Graduate Diplomas</li>
</ul>
<br/><br/>

<b>TYPES OF RESEARCH DEGREES</b>

<ul>

<li>Master of Philosophy (MPhil)</li>
<li>Master of Research (MRes) Professional/Taught </li><li>Doctorates (DEng, DMus, DEd, DBA)</li>
<li>Doctorate of Philosophy (PhD)</li>
<li>Doctor of Philosophy (PhD or DPhil)</li>
</ul>
        </p>


    </div>





    <div className='visadetails'>
        <p className='visadetailstxt1'>TOURIST VISA</p>
        <p className='visadetailstxt2'>
        - The U.S. offers tourist visas for individuals visiting friends or experiencing the country's natural beauty.<br/>
   - Visit visas are generally granted for a specified period and can be single or multiple entries.
   - While on a tourist visa, individuals cannot work but can freely travel for tourism and leisure. 

        {/* <b>DOCUMENTS REQUIRED</b><br/>
The Visit Visa process requires you to meet the following criteria:<br/>
<ul>
<li>Applicants must prove that they possess sufficient funds to support themselves and their family for the entire period of their visit.</li>
<li>Applicants must not intend to seek or undertake a job.</li>
<li>Applicants should have no criminal record and intend to strictly comply with the laws of the country.</li>
<li>Applicants who might stay either with their relatives or friends should also provide invitation letters from their sponsor.</li>
<li>Applicants from certain countries may need a temporary resident visa.</li>
<li>You may need to meet certain more entry requirements based on your profile.</li>
</ul> */}
        </p>
    </div>



    <div className='visadetails'>
        <p className='visadetailstxt1'>PERMANENT RESIDENCE</p>
        <p className='visadetailstxt2'>
        - The U.S. utilizes the Express Entry system for immigration, attracting skilled professionals and workers.<br/>
    - Federal and state governments, along with eligible employers, participate in the selection system.<br/>
    - Provincial Nominee Programs (PNPs) are available, aligning with federal programs like the Express Entry system.<br/><br/>

{/* A portion of the provinces and territories also select candidates in the Express Entry pool for their respective Provincial Nominee Programs to meet local market immigration requirements.<br/><br/>

Applicants ranked near the top of the pool based on Express Entry’s Comprehensive Ranking System will be issued an Invitation to Apply.On receiving their ITA, candidates have only 60 days to submit a complete and accurate e-application to Immigration, Refugees and Citizenship  (IRCC). Extensions will not be granted.<br/><br/>

Which is why we advise applicants to Get Ready even before they create their Express Entry profile.That means getting all supporting documentation for yourself and accompanying family members in order, assessed where necessary and ready for filing.
<br/><br/> */}

 <b>PROVINCIAL NOMINEE PROGRAMS</b><br/><br/>
 - U.S. states and territories have their own immigration programs targeting specific demographic needs.<br/>
    - Each PNP operates with its selection criteria, aiming to fulfill local economic and labor requirements.<br/>
    - PNPs often align with the federal Express Entry system to streamline the immigration process.
<br/><br/>

In summary, the United States provides a diverse and high-quality education, a welcoming cultural environment, and various immigration pathways for international students aiming for a rewarding educational experience and future prospects.

{/* Except for Quebec which has its own selection process, all Canadian provinces and two territories participate in the Provincial Nominee Programs.<br/><br/>

Each Provincial Nominee Program operates with its own selection criteria and guidelines to precisely target demographics of workers, students and business professionals with the skills, work experience, and knowledge to cater to grow the local economy and its labor needs.<br/><br/>

The participating programs have at least one immigration category that is aligned with the Federal Government’s Express Entry application and selection system.<br/><br/> */}

        </p>
    </div>

<Footer/>


    </div>
  
    
    
    </>
  )
}

export default Us