import React from 'react'
import { Link } from "react-router-dom";
import AboutVideo from '../Assets/aboutv.mp4'
import './About.css'
import Marquee from '../components/Marquee';
import Instapostsslider from "../components/Instapostsslider.jsx";

const About = () => {
  return (
   <>
    <div className="aboutsection">

        {/* MARQUEE SECTION  */}
        <div className="marq">
          <Marquee />
        </div>

        <div className="aboutsectionbox">
          <div className="aboutinnerbox">
            <p className="abouttext1"> WHY EVAA IMMIGRATION ?</p>

            {/* <p className={` abouttext2 ${isAboutVisible ? "fade-in" : ""}`}> */}
            <p className={` abouttext2 `}>
              At Evaa Immigration, we understand the transformative impact of
              international education on one's future. By combining personalized
              guidance with a wealth of experience, we empower our clients to
              make informed decisions, navigate the complexities of studying
              abroad, and ultimately shape a future filled with opportunities
              and success. As a steadfast companion on the journey to academic
              and professional advancement, Evaa Immigration remains committed
              to realizing the aspirations of individuals and contributing to
              their personal and professional growth.
            </p>
            <Link
                  className="Link "
                  to="/About" 
                >
            <button className="aboutbtn">More About Us</button> </Link>
          </div>
          <div className="aboutinnerbox">
            <video width="750" height="500" controls autoPlay muted className="shadow-1-strong rounded mb-4 aboutvideo" >
      <source src={AboutVideo} type="video/mp4"/>
</video>
          </div>
        </div>
      </div>
   </>
  )
}

export default About