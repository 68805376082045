import React , {useState} from 'react'
import './Contact.css'
import Check from '../Assets/check.gif'
import CVideo from '../Assets/contactvideo.mp4'
import Earth from '../Assets/Rotating_earth_(huge).gif'
import Cbg from '../Assets/cbg.png'

const Contact = () => {

    const [showTick, setShowTick] = useState(false);

    const [formData, setFormData] = useState({
        fullName: '',
        gmail: '',
        number:'',
        query: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const validatePhoneNumber = (phoneNumber) => {
        // Check if the phone number has exactly 10 digits
        return /^\d{10}$/.test(phoneNumber);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();

         // Validate phone number
    if (!validatePhoneNumber(formData.number)) {
      alert('Please enter a valid 10-digit phone number.');
      return;
    }

        setShowTick(true);
    
        const sheetDBEndpoint = process.env.REACT_APP_SHEETDB_API_KEY; 
    
        try {
          const response = await fetch(sheetDBEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            console.log('Data sent successfully');
            setShowTick(true);
            // Reset form data
            setFormData({
              fullName: '',
              gmail: '',
              number:'',
              query: '',
            });
    
              // Hide tick animation after 2 seconds (adjust as needed)
              setTimeout(() => {
                setShowTick(false);
              }, 2000);
          } else {
            console.error('Failed to send data');
            alert('Failed to send data, Please fill all details (correctly)');
            // Handle failure, e.g., show an error message to the user
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };


  return (
    <>
     {/* <div id="contactsection">
        <div className="contactformbox">
         

          
        </div>
      </div>

      */}


<div id="contactsection" className="video-background">
{/* <video autoPlay loop muted className="background-video">
        <source src={CVideo} type="video/mp4" />
      </video> */}
<div className='homecontactbox'>
  {/* <img src={Cbg} alt='earth' className='earth'/> */}
<div className="contactformbox">
<h4 className="fromboxtxt">APPLY NOW</h4>
<form className="contactform" onSubmit={handleSubmit}>
            <div class="mb-3">
              <label for="basic-url" class="form-label">
                Your Name *
              </label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  required
                  placeholder="Jhon Dew"
                  value={formData.fullName}
            onChange={handleChange}
            name="fullName"
                />
              </div>{" "}
            </div>

            <div class="mb-3">
              <label for="basic-url" class="form-label">
                Gmail *
              </label>
              <div class="input-group">
                <input
                  type="gmail"
                  class="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  required
                  placeholder="example@gmail.com"
                  name="gmail"
                  value={formData.gmail}
            onChange={handleChange} 
                />
              </div>{" "}
            </div>

            <div class="mb-3">
              <label for="basic-url" class="form-label">
                Phone Number *
              </label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon3">
                  +91
                </span>
                <input
                  type="number"
                  class="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  required
                  placeholder="8888XXXXXX"
                  name="number"
                  value={formData.number}
            onChange={handleChange} 
                />
              </div>{" "}
            </div>

            <div class="mb-3">
              <label for="basic-url" class="form-label">
                Your Query *
              </label>
              <div class="input-group">
                <textarea
                  class="form-control textarea"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                  required
                  placeholder="Enter Your Query ..."
                  name="query"
                  value={formData.query}
            onChange={handleChange}
                />
              </div>{" "}
            </div>

            <button type="submit" className="formbtn mt-3">SEND</button>
          </form>
</div>
</div>


{showTick && (
        <div className='messageok'>
        <div className="centered-div">
          <img src={Check} alt="Check" id='check' />
        </div>
        </div>
      )}



<div className='homecontactbox'>
<video autoPlay loop muted  className='cvideo'>
      <source src={CVideo} type="video/mp4"/>
     </video>
</div>


</div>
    </>
  )
}

export default Contact