import React from 'react'

const Contactdetailssec = () => {
  return (
    <div className='contactdetailsbox'>
        <div className='contactdetailsinnerbox'>
        <i class="bi bi-globe-central-south-asia"></i>
        <h5 className='contactdetailstxt1'>HEAD OFFICE</h5>
        <p className='contactdetailstxt2'>5170, Phase 2, Bypass Road, Opp Bal Bharti School, Urban Estate Dugri,  Ludhiana, Punjab 141013</p>
        </div>

        <div className='contactdetailsinnerbox'>
        <i class="bi bi-geo-alt-fill"></i>
        <h5 className='contactdetailstxt1'>BRANCH OFFICE</h5>
        <p className='contactdetailstxt2'>Mandi Ahmedgarh
Kalanaur Distt. Gurdaspur</p>
        </div>

        <div className='contactdetailsinnerbox'>
        <i class="bi bi-telephone-fill"></i>
        <h5 className='contactdetailstxt1'>CONTACT NUMBER</h5>
        <p className='contactdetailstxt2'>+91-95332-00003<br/>
+91-91157-57373 <br/>
+91-98083-00009</p>
        </div>
    </div>
  )
}

export default Contactdetailssec