import post1 from '../Assets/Gallery/1.webp'
import post2 from '../Assets/Gallery/2.webp'
import post3 from '../Assets/Gallery/3.webp'
import post4 from '../Assets/Gallery/4.webp'
import post5 from '../Assets/Gallery/5.webp'
import post6 from '../Assets/Gallery/6.webp'
import post7 from '../Assets/Gallery/7.webp'
import post8 from '../Assets/Gallery/8.webp'
import post9 from '../Assets/Gallery/9.webp'
import post10 from '../Assets/Gallery/10.webp'

import post11 from '../Assets/Gallery/11.webp'
import post12 from '../Assets/Gallery/12.webp'
import post13 from '../Assets/Gallery/13.webp'
import post14 from '../Assets/Gallery/14.webp'
import post15 from '../Assets/Gallery/15.jpg'
import post16 from '../Assets/Gallery/16.heic'
import post17 from '../Assets/Gallery/17.heic'
import post18 from '../Assets/Gallery/18.heic'
import post19 from '../Assets/Gallery/19.heic'
import post20 from '../Assets/Gallery/20.heic'




export const Instaposts = [
    {
        id:1,
        instapostpic: post1
    },
    {
        id:2,
        instapostpic: post2
    },{
        id:3,
        instapostpic: post3
    },{
        id:4,
        instapostpic: post4
    },{
        id:5,
        instapostpic: post5
    },{
        id:6,
        instapostpic: post6
    },{
        id:7,
        instapostpic: post7
    },{
        id:8,
        instapostpic: post8
    },{
        id:9,
        instapostpic: post9
    },{
        id:10,
        instapostpic: post10
    },
    {
        id:11,
        instapostpic: post11
    },
    {
        id:12,
        instapostpic: post12
    },
    {
        id:13,
        instapostpic: post13
    },
    {
        id:14,
        instapostpic: post14
    },
    {
        id:15,
        instapostpic: post15
    },
    {
        id:16,
        instapostpic: post16
    },
    {
        id:17,
        instapostpic: post17
    },

    {
        id:18,
        instapostpic: post18
    },
    {
        id:19,
        instapostpic: post19
    },{
        id:20,
        instapostpic: post20
    },
]