import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './css/Country.css'
import {Instaposts} from '../components/Instaposts'
import { Link } from 'react-router-dom'

import img1 from '../Assets/College/1.webp'
import img2 from '../Assets/College/2.webp'
import img3 from '../Assets/College/3.webp'
import img4 from '../Assets/College/4.webp'
import img5 from '../Assets/College/5.webp'
import img6 from '../Assets/College/6.webp'
import img7 from '../Assets/College/7.webp'
import img8 from '../Assets/College/8.webp'
import img9 from '../Assets/College/9.webp'
import img10 from '../Assets/College/10.webp'
import img11 from '../Assets/College/11.webp'
import img12 from '../Assets/College/12.webp'
import img13 from '../Assets/College/13.webp'
import img14 from '../Assets/College/14.webp'
import img15 from '../Assets/College/15.webp'
import img16 from '../Assets/College/16.webp'
import img17 from '../Assets/College/17.webp'
import img18 from '../Assets/College/18.webp'
import img19 from '../Assets/College/19.webp'
import img20 from '../Assets/College/20.webp'

import img21 from '../Assets/College/21.png'
import img22 from '../Assets/College/22.png'
import img23 from '../Assets/College/23.png'
import img24 from '../Assets/College/24.png'
import img25 from '../Assets/College/25.png'
import img26 from '../Assets/College/26.png'
import img27 from '../Assets/College/27.png'
import img31 from '../Assets/College/31.jpg'
import img28 from '../Assets/College/28.jpg'
import img29 from '../Assets/College/29.jpg'
import img30 from '../Assets/College/30.jpg'
import img32 from '../Assets/College/32.png'

const Canada = () => {

    // List of image URLs
  const imageUrls = [
    img1,img2,
    img3,img4,
    img5,img6,
    img7,img8,
    img9,img10,
    img11,img12,
    img13,img14,
    img15,img16,
    img17,img18,
    img19,img20,img21,img22,img23,img24,img25,img26,img27,img28,img29,img30,img31,img32
  ]


  return (
    <>
    <div className='Aboutussection'>
    <Navbar/>

    <nav style={{ '--bs-breadcrumb-divider': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E")' }} aria-label="breadcrumb" className='breadcrumb'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to='/' className='Link'>Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Canada</li>
      </ol>
    </nav>
    
    <p className='abouttxt1'>CANADA IMMIGRATION</p>


<div className='galbox'>

{imageUrls.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Image ${index + 1}`} className='Galleryposts cimg' />
      ))}
  
</div>
<hr/>
    
    <div className='visadetails'>
        <p className='visadetailstxt1'>STUDY VISA</p>
        <p className='visadetailstxt2'>
        <b>WHY STUDY IN CANADA?</b>
        <br/> <br/>
Canada’s high academic standards and rigorous quality controls mean that you’ll be earning a high-quality education that will open doors for your future and benefit your career over the long term. A Canadian degree, diploma or certificate is globally recognized as being equivalent to those obtained from the United States or Commonwealth countries. <br/> <br/>

The quality of education and living standards in Canada are amongst the highest in the world, but the cost of living and tuition fees for international students are generally lower than in other countries such as the United States and the United Kingdom. As such, Canada is often the preferred choice for students attending college or university. With almost all of the world’s ethnic groups represented in Canada, it’s hard not to find ethnic foods and recreation activities associated with specific cultures. In fact, your international student advisor can help you get in touch with any number of ethnic clubs and associations for you to join while you’re here. <br/> <br/>

While you may have heard of or experienced Canadians’ friendly and open nature, you may not have known that the United Nations consistently ranks Canada as one of the best places in the world to live. As an international student in Canada, you’ll enjoy all of the same freedoms which protect Canadians – respect for human rights, equality, and a stable and peaceful society. <br/> <br/>

Here are just a few ways that a Canadian study abroad program can benefit you.<br/><br/>

<ul>
    <li>First-rate education system with high standards.</li>
    <li>Immigration friendly country.</li>
    <li>Students can apply for P.R under C.E.C category.</li>
    <li>Range of job-focused programs with Co-op.</li>
    <li>Strong growing economy with not enough manpower to support the economy.</li>
    <li>Role model for the entire world in Health Care and therefore one of the best destinations for pursuing healthcare courses.</li>
    <li>Excellent industry-endorsed programs with paid internship (co-op) for Healthcare professionals and Engineers.</li>
    <li>Opportunity to stay back up to 3yrs and explore job & PR prospects.</li>
    <li>Options for students to study in colder provinces like Nova Scotia, Manitoba, Saskatchewan, Alberta & New Finland, where they have the possibilities to fast track their PR under PNP.</li>
</ul><br/><br/>

<b>TYPES OF TAUGHT DEGREES</b>

<ul>
    <li>Certificates and Diplomas</li>
    <li>Bachelor Degrees</li>
    <li>Masters Degrees</li>
    <li>Post Graduate Diplomas</li>
</ul>
<br/><br/>

<b>TYPES OF RESEARCH DEGREES</b>

<ul>

<li>Master of Philosophy (MPhil)</li>
<li>Master of Research (MRes) Professional/Taught </li><li>Doctorates (DEng, DMus, DEd, DBA)</li>
<li>Doctorate of Philosophy (PhD)</li>
<li>Doctor of Philosophy (PhD or DPhil)</li>
</ul>
        </p>


    </div>





    <div className='visadetails'>
        <p className='visadetailstxt1'>TOURIST VISA</p>
        <p className='visadetailstxt2'>
        A large number of guests and travelers run to Canada consistently to visit loved ones or to simply encounter Canada's regular excellence. Evaa Immigration Services can help you too with our snappy Canada Visit Visa administrations. The Canada visit visa is allowed for a time of 10 years and are either single section or various passage visas. While you cannot work on the visit visa, you are free to travel across Canada for tourism and leisure. <br/> <br/>

        <b>DOCUMENTS REQUIRED</b><br/>
The Visit Visa process requires you to meet the following criteria:<br/>
<ul>
<li>Applicants must prove that they possess sufficient funds to support themselves and their family for the entire period of their visit.</li>
<li>Applicants must not intend to seek or undertake a job.</li>
<li>Applicants should have no criminal record and intend to strictly comply with the laws of the country.</li>
<li>Applicants who might stay either with their relatives or friends should also provide invitation letters from their sponsor.</li>
<li>Applicants from certain countries may need a temporary resident visa.</li>
<li>You may need to meet certain more entry requirements based on your profile.</li>
</ul>
        </p>
    </div>



    <div className='visadetails'>
        <p className='visadetailstxt1'>PERMANENT RESIDENCE</p>
        <p className='visadetailstxt2'>
        A fully electronic application and selection system, Express Entry is designed to facilitate immigration by professionals and skilled workers to Canada. The Federal and provincial governments and eligible employers participate in the Express Entry selection system, which covers several of Canada’s immigrant programs, including Federal Skilled Worker, Canadian Experience Class, and Federal Skilled Trades.<br/><br/>

A portion of the provinces and territories also select candidates in the Express Entry pool for their respective Provincial Nominee Programs to meet local market immigration requirements.<br/><br/>

Applicants ranked near the top of the pool based on Express Entry’s Comprehensive Ranking System will be issued an Invitation to Apply.On receiving their ITA, candidates have only 60 days to submit a complete and accurate e-application to Immigration, Refugees and Citizenship Canada (IRCC). Extensions will not be granted.<br/><br/>

Which is why we advise applicants to Get Ready even before they create their Express Entry profile.That means getting all supporting documentation for yourself and accompanying family members in order, assessed where necessary and ready for filing.
<br/><br/>

 <b>PROVINCIAL NOMINEE PROGRAMS</b><br/><br/>
Canada’s Provincial Nominee Programs allow its provinces and territories to target specific types of immigrants and workers they need based on their economic and labor needs.<br/><br/>

Except for Quebec which has its own selection process, all Canadian provinces and two territories participate in the Provincial Nominee Programs.<br/><br/>

Each Provincial Nominee Program operates with its own selection criteria and guidelines to precisely target demographics of workers, students and business professionals with the skills, work experience, and knowledge to cater to grow the local economy and its labor needs.<br/><br/>

The participating programs have at least one immigration category that is aligned with the Federal Government’s Express Entry application and selection system.<br/><br/>

        </p>
    </div>

<Footer/>


    </div>
  
    
    
    </>
  )
}

export default Canada