import React from 'react'
import Navbar from '../components/Navbar'
import Contactdetailssec from '../components/Contactdetailssec'
import Footer from '../components/Footer'
import './css/About.css'
import Evaa from '../Assets/Evaaintro.mp4'
import { Link } from 'react-router-dom'
import AboutVideo from '../Assets/aboutv.mp4'

const About = () => {
  return (
    <>
    <div className='Aboutussection'>
    <Navbar/>

    <nav style={{ '--bs-breadcrumb-divider': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E")' }} aria-label="breadcrumb" className='breadcrumb'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to='/' className='Link'>Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">About Us</li>
      </ol>
    </nav>
    
    <p className='abouttxt1'>ABOUT EVAA IMMIGRATION</p>

    {/* <iframe width="560" height="315" src={Evaa} title="Evaa Intro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className='mt-5 aboutv'></iframe> */}

    <video  height="450" controls autoPlay muted className='mt-5 aboutv' >
      <source src={AboutVideo} type="video/mp4"/>
</video>

    <div className='socialicons'>
      <div className='ico'><i class="bi bi-facebook ico"></i></div>
      <div className='ico'><i class="bi bi-instagram ico"></i></div>
      <div className='ico'><i class="bi bi-youtube ico"></i></div>
    </div>


<p className='abouttxt2'>
Evaa Immigration is a distinguished organization dedicated to empowering individuals with unparalleled career guidance and vocational insights on a global scale. With a singular objective of fostering international education and facilitating overseas settlement, Evaa Immigration stands as a beacon of support for individuals aspiring to broaden their horizons. Our mission is rooted in addressing the diverse concerns of our valued clients, providing comprehensive solutions for those looking to pursue higher education abroad and embark on a journey toward a promising career.

<br/><br/>

Since its inception, Evaa Immigration has successfully transformed the aspirations of numerous students into reality. We take pride in our commitment to making the dreams of individuals come true by offering tailored advice, streamlined processes, and unwavering support throughout their educational and professional endeavors. With a focus on excellence and a client-centric approach, Evaa Immigration continues to be a trusted partner for those seeking to study abroad and enhance their career prospects.
<br/><br/>

Evaa Immigration has a vibrant experience in student services market. We have been operating in the Canadian market for many than ten years and have helped a large number of students to fulfill their dreams which come to Canada to study. We have provided consultancy to a large number of aspiring students, and many of them have already settled in Canada. Over the years have pioneered in study visa and our visa success rate is more than 95% which is best in the industry.

<br/><br/>

ICRCC certified. We want to make sure that the job is being done in the most professional way to our future clients/students. That is the why we believe, that our professionals that will be handling original and such important documents to our clients, must be certified. They will have the right knowledge to guide our clients with no mistakes.

<br/><br/>

At Evaa Immigration, we understand the transformative impact of international education on one's future. By combining personalized guidance with a wealth of experience, we empower our clients to make informed decisions, navigate the complexities of studying abroad, and ultimately shape a future filled with opportunities and success. As a steadfast companion on the journey to academic and professional advancement, Evaa Immigration remains committed to realizing the aspirations of individuals and contributing to their personal and professional growth.
</p>

<Contactdetailssec/>
<Footer/>


    </div>
  
    
    
    </>
  )
}

export default About