import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './css/Country.css'
import { Link } from 'react-router-dom'

import img1 from '../Assets/College/Australia/1.png'
import img2 from '../Assets/College/Australia/2.png'
import img3 from '../Assets/College/Australia/3.png'
import img4 from '../Assets/College/Australia/4.png'
import img5 from '../Assets/College/Australia/5.png'
import img6 from '../Assets/College/Australia/6.png'
import img7 from '../Assets/College/Australia/7.png'
import img8 from '../Assets/College/Australia/8.png'
import img9 from '../Assets/College/Australia/9.png'
import img10 from '../Assets/College/Australia/10.png'
import img11 from '../Assets/College/Australia/11.png'
import img12 from '../Assets/College/Australia/12.png'
import img13 from '../Assets/College/Australia/13.png'
import img14 from '../Assets/College/Australia/14.png'
import img15 from '../Assets/College/Australia/15.png'
import img16 from '../Assets/College/Australia/16.png'
import img17 from '../Assets/College/Australia/17.png'
import img18 from '../Assets/College/Australia/18.png'
import img19 from '../Assets/College/Australia/19.png'
import img20 from '../Assets/College/Australia/20.png'

import img21 from '../Assets/College/Australia/21.png'
import img22 from '../Assets/College/Australia/22.png'
import img23 from '../Assets/College/Australia/23.png'
import img24 from '../Assets/College/Australia/24.png'
import img25 from '../Assets/College/Australia/25.png'
import img26 from '../Assets/College/Australia/26.png'
import img27 from '../Assets/College/Australia/27.png'
import img28 from '../Assets/College/Australia/28.png'
import img29 from '../Assets/College/Australia/29.png'
import img30 from '../Assets/College/Australia/30.png'
import img31 from '../Assets/College/Australia/31.png'
import img32 from '../Assets/College/Australia/32.png'
import img33 from '../Assets/College/Australia/33.png'
import img34 from '../Assets/College/Australia/34.png'
import img35 from '../Assets/College/Australia/35.png'
import img36 from '../Assets/College/Australia/36.png'
import img37 from '../Assets/College/Australia/37.png'
import img38 from '../Assets/College/Australia/38.png'
import img39 from '../Assets/College/Australia/39.png'
import img40 from '../Assets/College/Australia/40.png'
import img41 from '../Assets/College/Australia/41.png'
import img42 from '../Assets/College/Australia/42.png'
// import img43 from '../Assets/College/Australia/43.png'

const Australia = () => {

    // List of image URLs
  const imageUrls = [
    img1,img2,img21,img22,
    img3,img4,img23,img24,
    img5,img6,img25,img26,
    img7,img8,img27,img28,
    img9,img10,img29,img30,
    img11,img12,img31,img32,
    img13,img14,img33,img34,
    img15,img16,img35,img36,
    img17,img18,img37,img38,
    img19,img20,img39,img40,
    img41,img42,
  ]


  return (
    <>
    <div className='AboutAustraliasection'>
    <Navbar/>

    <nav style={{ '--bs-breadcrumb-divider': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E")' }} aria-label="breadcrumb" className='breadcrumb'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to='/' className='Link'>Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Australia</li>
      </ol>
    </nav>
    
    <p className='abouttxt1'>AUSTRALIA IMMIGRATION</p>


<div className='galbox'>

{imageUrls.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Image ${index + 1}`} className='Galleryposts cimg' />
      ))}
  
</div>
<hr/> 
    <div className='visadetails'>
        <p className='visadetailstxt1'>STUDY VISA</p>
        <p className='visadetailstxt2'>
Australia high academic standards and rigoroAustralia quality controls mean that you’ll be earning a high-quality education that will open doors for your future and benefit your career over the long term. A Canadian degree, diploma or certificate is globally recognized as being equivalent to those obtained from the United States or Commonwealth countries. <br/> <br/>

The quality of education and living standards in  are amongst the highest in the world, but the cost of living and tuition fees for international students are generally lower than in other countries such as the United States and the United Kingdom. As such,  is often the preferred choice for students attending college or university. With almost all of the world’s ethnic groups represented in , it’s hard not to find ethnic foods and recreation activities associated with specific cultures. In fact, your international student advisor can help you get in touch with any number of ethnic clubs and associations for you to join while you’re here. <br/> <br/>

WHY STUDY IN AUSTRALIA<br/><br/>

<ul>
    <li>High Academic Standards: Australia is known for its high academic standards and stringent quality controls, ensuring students receive a top-tier education. Degrees earned in Australia are widely recognized and respected globally.
</li>
    <li>Global Recognition: Australian degrees, diplomas, and certificates are globally acknowledged and considered equivalent to those obtained from the United States or Commonwealth countries.</li>
    <li>Quality of Education and Living Standards: Australia boasts one of the world's highest qualities of education and living standards. The cost of living and tuition fees for international students is generally lower than in countries like the United States and the United Kingdom, making it an attractive option.</li>
    <li>Cultural Diversity: With almost all of the world's ethnic groups represented, Australia offers a rich cultural experience. Students can enjoy diverse ethnic foods and engage in recreational activities associated with specific cultures, facilitated by numerous ethnic clubs and associations.</li>
    <li>Friendly and Open Environment: Australians are known for their friendly and open nature. The country consistently ranks high in global livability indices, offering a stable and peaceful society with a strong commitment to human rights and equality.
</li>
    <li>Benefits of an Australian Study Program:
   - High-quality education with strict standards.<br/>
   - Immigration-friendly country with post-study work opportunities.<br/>
   - Opportunities to apply for Permanent Residency (P.R.) under various categories, including the C.E.C category.<br/>
   - Job-focused programs with Co-op (internship) options.<br/>
   - Strong and growing economy with a demand for skilled professionals.<br/>
   - A role model in healthcare, making it one of the best destinations for pursuing healthcare courses.<br/>
   - Excellent industry-endorsed programs with paid internships (co-op) for healthcare professionals and engineers.<br/>
   - Opportunity to stay back for up to 3 years to explore job and PR prospects.<br/>
   - Options for students to study in colder provinces like Nova Scotia, Manitoba, Saskatchewan, Alberta, and Newfoundland, with possibilities to fast-track PR under PNP.</li>
</ul><br/><br/>

<b>TYPES OF TAUGHT DEGREES</b>

<ul>
    <li>Certificates and Diplomas</li>
    <li>Bachelor Degrees</li>
    <li>Masters Degrees</li>
    <li>Post Graduate Diplomas</li>
</ul>
<br/><br/>

<b>TYPES OF RESEARCH DEGREES</b>

<ul>

<li>Master of Philosophy (MPhil)</li>
<li>Master of Research (MRes) Professional/Taught </li><li>Doctorates (DEng, DMAustralia, DEd, DBA)</li>
<li>Doctorate of Philosophy (PhD)</li>
<li>Doctor of Philosophy (PhD or DPhil)</li>
</ul>
        </p>


    </div>





    <div className='visadetails'>
        <p className='visadetailstxt1'>TOURIST VISA</p>
        <p className='visadetailstxt2'>
        - Australia attracts a large number of tourists annually due to its natural beauty.<br/>
   - Tourist visas are typically granted for a specified period, allowing visitors to experience the country's attractions and visit friends and family.

        {/* <b>DOCUMENTS REQUIRED</b><br/>
The Visit Visa process requires you to meet the following criteria:<br/>
<ul>
<li>Applicants mAustraliat prove that they possess sufficient funds to support themselves and their family for the entire period of their visit.</li>
<li>Applicants mAustraliat not intend to seek or undertake a job.</li>
<li>Applicants should have no criminal record and intend to strictly comply with the laws of the country.</li>
<li>Applicants who might stay either with their relatives or friends should also provide invitation letters from their sponsor.</li>
<li>Applicants from certain countries may need a temporary resident visa.</li>
<li>You may need to meet certain more entry requirements based on your profile.</li>
</ul> */}
        </p>
    </div>



    <div className='visadetails'>
        <p className='visadetailstxt1'>PERMANENT RESIDENCE</p>
        <p className='visadetailstxt2'>
        - Australia's immigration system includes a fully electronic application and selection system, facilitating immigration for professionals and skilled workers.<br/>
    - The system, like Express Entry in Canada, covers various immigrant programs, including the Skilled Migration Program and regional nomination pathways.<br/><br/>

{/* A portion of the provinces and territories also select candidates in the Express Entry pool for their respective Provincial Nominee Programs to meet local market immigration requirements.<br/><br/>

Applicants ranked near the top of the pool based on Express Entry’s Comprehensive Ranking System will be issued an Invitation to Apply.On receiving their ITA, candidates have only 60 days to submit a complete and accurate e-application to Immigration, Refugees and Citizenship  (IRCC). Extensions will not be granted.<br/><br/>

Which is why we advise applicants to Get Ready even before they create their Express Entry profile.That means getting all supporting documentation for yourself and accompanying family members in order, assessed where necessary and ready for filing.
<br/><br/> */}

 <b>PROVINCIAL NOMINEE PROGRAMS</b><br/><br/>
 - Australia has its own version of regional nomination programs, allowing states and territories to nominate candidates based on local economic and labor needs.<br/>
    - These programs operate with their criteria, aligning with federal immigration goals.<br/><br/>

In summary, Australia provides a high-quality education, a welcoming cultural environment, and various immigration pathways for international students seeking a rewarding educational experience and future opportunities.

        </p>
    </div>

<Footer/>


    </div>
  
    
    
    </>
  )
}

export default Australia