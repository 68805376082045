import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './css/Contact.css'
import { Contactdata } from '../components/Contactdata'
import { Link } from 'react-router-dom'
import Form from '../components/Form'

const Contact = () => {



  return (
    <>
    <div className='Aboutussection'>
    <Navbar/>

    <nav style={{ '--bs-breadcrumb-divider': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E")' }} aria-label="breadcrumb" className='breadcrumb'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to='/' className='Link'>Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
      </ol>
    </nav>
    
    <p className='abouttxt1'>CONTACT US</p>

    <Form/>

    {/* <form className='contactusform'>
      <p>ASK YOUR QUERY</p>

      <div class="mb-3 mt-5">
  <label for="basic-url" class="form-label">Full Name</label>
  <div class="input-group">
    <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='Jhon Dew'/>
  </div>
</div>

<div class="mb-3">
  <label for="basic-url" class="form-label">Gmail</label>
  <div class="input-group">
    <input type="email" class="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='example@gmail.com'/>
  </div>
</div>

<div class="mb-3">
  <label for="basic-url" class="form-label">Your Query</label>
  <div class="input-group">
    <textarea type="text" class="form-control textarea" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='Your Query Here !'/>
  </div>
</div>

<button>SEND</button>
    </form> */}

    <p className='abouttxt1 mt-5'>OUR OFFICES</p>

    {Contactdata.map(Contactdata => (
          <div className="Contactdata" key={Contactdata.id}>
          
          <div className='contactalldetails'><h4>{Contactdata.place}</h4>
          
          <div className='contactbox'>
            <div className='contactinnerbox'>
            <i class="bi bi-geo-alt-fill cicon"></i>
           {Contactdata.address } 
            </div>
            <div className='contactinnerbox'>
            <i class="bi bi-telephone-fill cicon"></i>
            {Contactdata.phone}
            </div>
            <div className='contactinnerbox'>
            <i class="bi bi-envelope-fill cicon"></i>
            {Contactdata.email}
            </div>
          </div>
        <iframe id='iframe' src={Contactdata.map}   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='map'></iframe>
        <br/><br/><hr/></div>

          </div>
        ))}


<Footer/>


    </div>
  
    </>
  )
}

export default Contact