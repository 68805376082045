import React from 'react'
import './Testimonials.css'
import { Link } from "react-router-dom";
import Instapostsslider from '../components/Instapostsslider'

const Testimonials = () => {
  return (
    <>
    <div className="gallerysection">
        <p className="gallerysectiontxt">
          <b>TESTIMONIALS</b> GALLERY
        </p>

        <div>
          <Instapostsslider />
        </div>

        <Link
                  className="Link "
                  to="/Gallery" 
                >
        <button className="galleybtn">
          More &nbsp; <i class="bi bi-arrow-right"></i>
        </button></Link>
      </div>
    </>
  )
}

export default Testimonials