import React, { useRef, useEffect, useState } from "react";
import "./Hero.css";
import Navbar from "../components/Navbar";
import herosecbg from "../Assets/bg1.mp4";
import herosecbg2 from "../Assets/bg5.png";


const Hero = () => {

  return (
    <>
    <div id="herosection">
        <img src={herosecbg2} alt="HERO SECTION" className="herosecbg" />
        <Navbar/>
        <div className="herosectioncontainer">
          <div className="herosectionbox box1">
            <video
              src={herosecbg}
              autoPlay
              muted
              loop
              className="herovideo"
              controls=""
            />
          </div>

          <div className="herosectionbox">
            <div className="herosectiontxt">
              <p className="herosectiontxt1">
                Study Abroad & Enhance Your Career Prospects
              </p>

              {/* <p className={`herosectiontxt2  ${isVisible ? "fade-in" : ""}`}> */}
              <p className={`herosectiontxt2 fade-in`}>
                Evaa Immigration is a distinguished organization dedicated to
                empowering individuals with unparalleled career guidance and
                vocational insights on a global scale. With a singular objective
                of fostering international education and facilitating overseas
                settlement, Evaa Immigration stands as a beacon of support for
                individuals aspiring to broaden their horizons.
              </p>
              <a
                  className="Link "
                  href="#contactsection" 
                >
              <button className="herosectionbtn">Apply Now</button> </a>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Hero