import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './css/Gallery.css'
import {Instaposts} from '../components/Instaposts'
import { Link } from 'react-router-dom'

const Gallery = () => {
  return (
    <>
    <div className='Aboutussection'>
    <Navbar/>

    <nav style={{ '--bs-breadcrumb-divider': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E")' }} aria-label="breadcrumb" className='breadcrumb'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to='/' className='Link'>Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Gallery</li>
      </ol>
    </nav>
    
    <p className='abouttxt1'>GALLERY OF EVAA IMMIGRATION</p>


<div className='galbox'>

{Instaposts.map(Instaposts => (
          <div className="galpics" key={Instaposts.id}>

            <img src={Instaposts.instapostpic} alt='Gallery' className='Galleryposts mb-2'/>
            
          </div>
        ))}

{/* {Instaposts.map(Instaposts => (
          <div className="galpics" key={Instaposts.id}>

            <img src={Instaposts.instapostpic} alt='Gallery' className='Galleryposts mb-2'/>
            
          </div>
        ))} */}
  
</div>
    

<Footer/>


    </div>
  
    
    
    </>
  )
}

export default Gallery