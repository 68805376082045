import React from "react";
import CVideo from '../Assets/aboutv.mp4'; 
import "./component.css"; 

const VideoModal = ({ onClose }) => {
  return (
    <div className="video-modal-overlay">
      <div className="video-modal-content">
        <video autoPlay controls className="background-video">
          <source src={CVideo} type="video/mp4" />
        </video>
      </div>
      <button className="cut-button" onClick={onClose}>
          X
        </button>
    </div>
  );
};

export default VideoModal;
