import React from 'react'
import './Services.css'

const Services = () => {
  return (
    <>
    <div className="servicesection">
        <h3 className="mt-5">OUR SERVICES</h3>
        <div className="servicesectioncontainer">
          <div className="servicesectionbox">
            <div className="servicesectionboxinner">
              <i class="bi bi-book"></i>
              <h4
                className={`servicesectiontxt`}
               
              >
                STUDY VISA
              </h4>
            </div>
          </div>

          <div className="servicesectionbox">
            <div className="servicesectionboxinner">
              <i class="bi bi-briefcase"></i>
              <h4
                className={`servicesectiontxt `}
              >
                VISITOR/TOURIST
              </h4>
            </div>
          </div>

          <div className="servicesectionbox">
            <div className="servicesectionboxinner">
              <i class="bi bi-globe-central-south-asia"></i>
              <h4
                className={`servicesectiontxt`}
              >
                SPOUSE OPEN WORK PERMIT
              </h4>
            </div>
          </div>

          <div className="servicesectionbox">
            <div className="servicesectionboxinner">
              <i class="bi bi-house"></i>
              <h4
                className={`servicesectiontxt`}
              >
                PERMANENT RESIDENCE
              </h4>
            </div>
          </div>
        </div>
        <div className="getaquotebox">
          <p className="getaquoteboxtxt1">
            NEED A <b>CONSULTATION?</b>
          </p>
          <p className="getaquoteboxtxt2">
            BOOK AN APPOINTMENT WITH EVAA IMMIGRATION EXPERTS
          </p>
          <a href="#contactsection" style={{textDecoration:"none"}}>
          <button className="getaquoteboxbtn">Book An Appointment</button></a>
        </div>
      </div>

    </>
  )
}

export default Services